const region = [{
    "label": "西宁市",
    "value": "630100000000",
    "children": [{
        "label": "市辖区",
        "value": "630101000000",
        "children": null
    }, {
        "label": "城东区",
        "value": "630102000000",
        "children": [{
            "label": "东关大街社区公共服务中心",
            "value": "630102001000",
            "children": [{
                "label": "北关社区居民委员会",
                "value": "630102001001",
                "children": null
            }, {
                "label": "慈幼社区居民委员会",
                "value": "630102001004",
                "children": null
            }, {
                "label": "五一社区居民委员会",
                "value": "630102001005",
                "children": null
            }]
        }, {
            "label": "清真巷社区公共服务中心",
            "value": "630102002000",
            "children": [{
                "label": "团结社区居民委员会",
                "value": "630102002003",
                "children": null
            }, {
                "label": "夏都花园社区居民委员会",
                "value": "630102002006",
                "children": null
            }, {
                "label": "南小街社区居民委员会",
                "value": "630102002007",
                "children": null
            }]
        }, {
            "label": "大众街社区公共服务中心",
            "value": "630102003000",
            "children": [{
                "label": "凯旋社区居民委员会",
                "value": "630102003001",
                "children": null
            }, {
                "label": "园山路社区居民委员会",
                "value": "630102003002",
                "children": null
            }]
        }, {
            "label": "周家泉社区公共服务中心",
            "value": "630102004000",
            "children": [{
                "label": "杨家巷社区居民委员会",
                "value": "630102004001",
                "children": null
            }, {
                "label": "建国路社区居民委员会",
                "value": "630102004002",
                "children": null
            }, {
                "label": "为民巷社区居民委员会",
                "value": "630102004004",
                "children": null
            }]
        }, {
            "label": "火车站社区公共服务中心",
            "value": "630102005000",
            "children": [{
                "label": "火车站社区居民委员会",
                "value": "630102005001",
                "children": null
            }, {
                "label": "中庄社区居民委员会",
                "value": "630102005004",
                "children": null
            }]
        }, {
            "label": "康乐社区公共服务中心",
            "value": "630102006000",
            "children": [{
                "label": "学院社区居民委员会",
                "value": "630102006002",
                "children": null
            }, {
                "label": "康东社区居民委员会",
                "value": "630102006003",
                "children": null
            }]
        }, {
            "label": "林家崖社区公共服务中心",
            "value": "630102007000",
            "children": [{
                "label": "蓝天社区居民委员会",
                "value": "630102007001",
                "children": null
            }, {
                "label": "站西社区居民委员会",
                "value": "630102007004",
                "children": null
            }]
        }, {
            "label": "国际村社区公共服务中心",
            "value": "630102008000",
            "children": [{
                "label": "凤凰园社区居民委员会",
                "value": "630102008001",
                "children": null
            }, {
                "label": "磨尔园社区居民委员会",
                "value": "630102008002",
                "children": null
            }]
        }, {
            "label": "富强巷社区公共服务中心",
            "value": "630102009000",
            "children": [{
                "label": "树林巷社区居民委员会",
                "value": "630102009001",
                "children": null
            }, {
                "label": "共和路社区居民委员会",
                "value": "630102009002",
                "children": null
            }, {
                "label": "德令哈社区居民委员会",
                "value": "630102009003",
                "children": null
            }]
        }, {
            "label": "青藏花园社区公共服务中心",
            "value": "630102010000",
            "children": [{
                "label": "康南社区居民委员会",
                "value": "630102010001",
                "children": null
            }, {
                "label": "康宁社区居民委员会",
                "value": "630102010002",
                "children": null
            }, {
                "label": "社区居民委员会",
                "value": "630102011001",
                "children": null
            }, {
                "label": "社区居民委员会",
                "value": "630102011002",
                "children": null
            }, {
                "label": "幸福社区居民委员会",
                "value": "630102012001",
                "children": null
            }, {
                "label": "纺织社区居民委员会",
                "value": "630102012002",
                "children": null
            }]
        }, {
            "label": "泰宁社区公共服务中心",
            "value": "630102011000",
            "children": [{
                "label": "安泰社区居民委员会",
                "value": "630102011001",
                "children": null
            }, {
                "label": "康西社区居民委员会",
                "value": "630102011002",
                "children": null
            }]
        }, {
            "label": "互助中路社区公共服务中心",
            "value": "630102012000",
            "children": [{
                "label": "幸福社区居民委员会",
                "value": "630102012001",
                "children": null
            }, {
                "label": "纺织社区居民委员会",
                "value": "630102012002",
                "children": null
            }]
        }, {
            "label": "乐家湾镇政府",
            "value": "630102100000",
            "children": [{
                "label": "乐家湾社区居民委员会",
                "value": "630102100001",
                "children": null
            }, {
                "label": "金桥路社区居民委员会",
                "value": "630102100002",
                "children": null
            }, {
                "label": "上铺村村民委员会",
                "value": "630102100200",
                "children": null
            }, {
                "label": "塔尔山村村民委员会",
                "value": "630102100201",
                "children": null
            }, {
                "label": "下铺村村民委员会",
                "value": "630102100202",
                "children": null
            }, {
                "label": "乐家湾村村民委员会",
                "value": "630102100203",
                "children": null
            }, {
                "label": "杨沟湾村村民委员会",
                "value": "630102100204",
                "children": null
            }]
        }, {
            "label": "韵家口镇政府",
            "value": "630102101000",
            "children": [{
                "label": "东兴社区居民委员会",
                "value": "630102101003",
                "children": null
            }, {
                "label": "东盛社区居民委员会",
                "value": "630102101004",
                "children": null
            }, {
                "label": "中庄村村民委员会",
                "value": "630102101200",
                "children": null
            }, {
                "label": "褚家营村村民委员会",
                "value": "630102101201",
                "children": null
            }, {
                "label": "小寨村村民委员会",
                "value": "630102101202",
                "children": null
            }, {
                "label": "韵家口村民委员会",
                "value": "630102101203",
                "children": null
            }, {
                "label": "泮子山村村民委员会",
                "value": "630102101204",
                "children": null
            }, {
                "label": "付家寨村村民委员会",
                "value": "630102101205",
                "children": null
            }, {
                "label": "朱家庄村村民委员会",
                "value": "630102101206",
                "children": null
            }, {
                "label": "友谊村村民委员会",
                "value": "630102101207",
                "children": null
            }, {
                "label": "团结村村民委员会",
                "value": "630102101208",
                "children": null
            }, {
                "label": "先进村村民委员会",
                "value": "630102101209",
                "children": null
            }, {
                "label": "联合村村民委员会",
                "value": "630102101210",
                "children": null
            }, {
                "label": "王家庄村村民委员会",
                "value": "630102101211",
                "children": null
            }, {
                "label": "曹家寨村村民委员会",
                "value": "630102101212",
                "children": null
            }, {
                "label": "林家崖村村民委员会",
                "value": "630102101213",
                "children": null
            }, {
                "label": "路家庄村村民委员会",
                "value": "630102101214",
                "children": null
            }]
        }]
    }, {
        "label": "城中区",
        "value": "630103000000",
        "children": [{
            "label": "人民街街道办事处",
            "value": "630103001000",
            "children": [{
                "label": "南关街社区",
                "value": "630103001003",
                "children": null
            }, {
                "label": "水井巷社区",
                "value": "630103001004",
                "children": null
            }]
        }, {
            "label": "南滩街道办事处",
            "value": "630103002000",
            "children": [{
                "label": "农建社区",
                "value": "630103002001",
                "children": null
            }, {
                "label": "南山东社区",
                "value": "630103002003",
                "children": null
            }, {
                "label": "南山西社区",
                "value": "630103002004",
                "children": null
            }, {
                "label": "南山社区",
                "value": "630103002005",
                "children": null
            }, {
                "label": "新青社区",
                "value": "630103002006",
                "children": null
            }, {
                "label": "建新社区",
                "value": "630103002007",
                "children": null
            }]
        }, {
            "label": "仓门街街道办事处",
            "value": "630103003000",
            "children": [{
                "label": "石坡街社区",
                "value": "630103003001",
                "children": null
            }, {
                "label": "前营街社区",
                "value": "630103003002",
                "children": null
            }]
        }, {
            "label": "礼让街街道办事处",
            "value": "630103004000",
            "children": [{
                "label": "长江路社区",
                "value": "630103004002",
                "children": null
            }, {
                "label": "七一路西社区",
                "value": "630103004003",
                "children": null
            }, {
                "label": "解放路社区",
                "value": "630103004005",
                "children": null
            }]
        }, {
            "label": "饮马街街道办事处",
            "value": "630103005000",
            "children": [{
                "label": "东大街社区",
                "value": "630103005001",
                "children": null
            }, {
                "label": "上滨河路社区",
                "value": "630103005002",
                "children": null
            }, {
                "label": "文化街社区",
                "value": "630103005004",
                "children": null
            }]
        }, {
            "label": "南川东路街道办事处",
            "value": "630103006000",
            "children": [{
                "label": "龙泰社区",
                "value": "630103006001",
                "children": null
            }, {
                "label": "二机社区",
                "value": "630103006002",
                "children": null
            }, {
                "label": "兴旺社区",
                "value": "630103006003",
                "children": null
            }, {
                "label": "瑞驰社区",
                "value": "630103006006",
                "children": null
            }, {
                "label": "水磨村村民委员会",
                "value": "630103006200",
                "children": null
            }, {
                "label": "红光村村民委员会",
                "value": "630103006201",
                "children": null
            }, {
                "label": "南酉山村村民委员会",
                "value": "630103006202",
                "children": null
            }, {
                "label": "南园村村民委员会",
                "value": "630103006203",
                "children": null
            }]
        }, {
            "label": "南川西路街道办事处",
            "value": "630103007000",
            "children": [{
                "label": "福禄巷北社区居民委员会",
                "value": "630103007001",
                "children": null
            }, {
                "label": "西台社区居民委员会",
                "value": "630103007003",
                "children": null
            }, {
                "label": "东台社区居民委员会",
                "value": "630103007004",
                "children": null
            }, {
                "label": "福禄巷南社区居民委员会",
                "value": "630103007005",
                "children": null
            }, {
                "label": "香格里拉社区",
                "value": "630103007007",
                "children": null
            }, {
                "label": "沈家寨村",
                "value": "630103007200",
                "children": null
            }, {
                "label": "红星村",
                "value": "630103007201",
                "children": null
            }, {
                "label": "新青村",
                "value": "630103007202",
                "children": null
            }, {
                "label": "园树村",
                "value": "630103007203",
                "children": null
            }, {
                "label": "贾小村",
                "value": "630103007204",
                "children": null
            }]
        }, {
            "label": "总寨镇",
            "value": "630103100000",
            "children": [{
                "label": "清华路社区",
                "value": "630103100002",
                "children": null
            }, {
                "label": "金十字社区",
                "value": "630103100003",
                "children": null
            }, {
                "label": "新城社区",
                "value": "630103100004",
                "children": null
            }, {
                "label": "王斌堡村",
                "value": "630103100200",
                "children": null
            }, {
                "label": "张家庄村",
                "value": "630103100201",
                "children": null
            }, {
                "label": "清河村",
                "value": "630103100202",
                "children": null
            }, {
                "label": "清水河村",
                "value": "630103100203",
                "children": null
            }, {
                "label": "总南村",
                "value": "630103100204",
                "children": null
            }, {
                "label": "总北村",
                "value": "630103100205",
                "children": null
            }, {
                "label": "谢家寨村",
                "value": "630103100206",
                "children": null
            }, {
                "label": "塘马坊村",
                "value": "630103100207",
                "children": null
            }, {
                "label": "杜家庄村",
                "value": "630103100208",
                "children": null
            }, {
                "label": "泉尔湾村",
                "value": "630103100209",
                "children": null
            }, {
                "label": "新庄村",
                "value": "630103100210",
                "children": null
            }, {
                "label": "逯家寨村",
                "value": "630103100211",
                "children": null
            }, {
                "label": "王家山村",
                "value": "630103100212",
                "children": null
            }, {
                "label": "元堡子村",
                "value": "630103100213",
                "children": null
            }, {
                "label": "星家村",
                "value": "630103100214",
                "children": null
            }, {
                "label": "新安村",
                "value": "630103100215",
                "children": null
            }, {
                "label": "享堂村",
                "value": "630103100216",
                "children": null
            }, {
                "label": "陈家窑村",
                "value": "630103100217",
                "children": null
            }, {
                "label": "莫家沟村",
                "value": "630103100218",
                "children": null
            }, {
                "label": "下细沟村",
                "value": "630103100219",
                "children": null
            }, {
                "label": "上细沟村",
                "value": "630103100220",
                "children": null
            }, {
                "label": "下野牛沟村",
                "value": "630103100221",
                "children": null
            }, {
                "label": "上野牛沟村",
                "value": "630103100222",
                "children": null
            }]
        }, {
            "label": "南川工业园",
            "value": "630103400000",
            "children": [{
                "label": "南川工业园虚拟社区",
                "value": "630103400498",
                "children": null
            }]
        }]
    }, {
        "label": "城西区",
        "value": "630104000000",
        "children": [{
            "label": "西关大街街道办事处",
            "value": "630104001000",
            "children": [{
                "label": "北气象巷社区居民委员会",
                "value": "630104001001",
                "children": null
            }, {
                "label": "南气象巷社区居民委员会",
                "value": "630104001002",
                "children": null
            }, {
                "label": "贾小社区居民委员会",
                "value": "630104001003",
                "children": null
            }]
        }, {
            "label": "古城台街道办事处",
            "value": "630104002000",
            "children": [{
                "label": "学院巷社区居民委员会",
                "value": "630104002001",
                "children": null
            }, {
                "label": "青年巷社区居民委员会",
                "value": "630104002002",
                "children": null
            }, {
                "label": "昆东社区居民委员会",
                "value": "630104002004",
                "children": null
            }, {
                "label": "昆西社区居民委员会",
                "value": "630104002005",
                "children": null
            }]
        }, {
            "label": "虎台街道办事处",
            "value": "630104003000",
            "children": [{
                "label": "海晏路社区居民委员会",
                "value": "630104003002",
                "children": null
            }, {
                "label": "医财东社区居民委员会",
                "value": "630104003003",
                "children": null
            }, {
                "label": "医财西社区居民委员会",
                "value": "630104003004",
                "children": null
            }, {
                "label": "冷湖路社区居民委员会",
                "value": "630104003005",
                "children": null
            }, {
                "label": "新西社区居民委员会",
                "value": "630104003006",
                "children": null
            }, {
                "label": "殷家庄社区居民委员会",
                "value": "630104003007",
                "children": null
            }, {
                "label": "杨家寨村",
                "value": "630104003201",
                "children": null
            }, {
                "label": "苏家河湾村",
                "value": "630104003202",
                "children": null
            }]
        }, {
            "label": "胜利路街道办事处",
            "value": "630104004000",
            "children": [{
                "label": "西交通巷社区居民委员会",
                "value": "630104004001",
                "children": null
            }, {
                "label": "公园巷社区居民委员会",
                "value": "630104004002",
                "children": null
            }, {
                "label": "东交通巷社区居民委员会",
                "value": "630104004003",
                "children": null
            }, {
                "label": "北商业巷社区居民委员会",
                "value": "630104004004",
                "children": null
            }]
        }, {
            "label": "兴海路街道办事处",
            "value": "630104005000",
            "children": [{
                "label": "兴胜巷社区居民委员会",
                "value": "630104005001",
                "children": null
            }, {
                "label": "中华巷社区居民委员会",
                "value": "630104005003",
                "children": null
            }, {
                "label": "尕寺巷社区居民委员会",
                "value": "630104005005",
                "children": null
            }]
        }, {
            "label": "文汇路街道办事处",
            "value": "630104006000",
            "children": [{
                "label": "文亭巷社区居民委员会",
                "value": "630104006001",
                "children": null
            }, {
                "label": "文博路社区居民委员会",
                "value": "630104006002",
                "children": null
            }, {
                "label": "海湖广场社区居民委员会",
                "value": "630104006003",
                "children": null
            }]
        }, {
            "label": "通海路街道办事处",
            "value": "630104007000",
            "children": [{
                "label": "文成路社区居民委员会",
                "value": "630104007001",
                "children": null
            }, {
                "label": "桃李路社区居民委员会",
                "value": "630104007002",
                "children": null
            }, {
                "label": "光华路社区居民委员会",
                "value": "630104007003",
                "children": null
            }]
        }, {
            "label": "彭家寨镇",
            "value": "630104100000",
            "children": [{
                "label": "西川南路社区居民委员会",
                "value": "630104100001",
                "children": null
            }, {
                "label": "彭家寨村",
                "value": "630104100200",
                "children": null
            }, {
                "label": "西北园村",
                "value": "630104100201",
                "children": null
            }, {
                "label": "刘家寨村",
                "value": "630104100202",
                "children": null
            }, {
                "label": "汉庄村",
                "value": "630104100203",
                "children": null
            }, {
                "label": "张家湾村",
                "value": "630104100204",
                "children": null
            }, {
                "label": "杨家湾村",
                "value": "630104100205",
                "children": null
            }, {
                "label": "阴山堂村",
                "value": "630104100206",
                "children": null
            }, {
                "label": "火西村",
                "value": "630104100207",
                "children": null
            }, {
                "label": "火东村",
                "value": "630104100208",
                "children": null
            }, {
                "label": "晨光村",
                "value": "630104100209",
                "children": null
            }]
        }]
    }, {
        "label": "城北区",
        "value": "630105000000",
        "children": [{
            "label": "朝阳街道办事处",
            "value": "630105001000",
            "children": [{
                "label": "朝阳社区居民委员会",
                "value": "630105001001",
                "children": null
            }, {
                "label": "山川社区居民委员会",
                "value": "630105001002",
                "children": null
            }, {
                "label": "北川河东路社区居民委员会",
                "value": "630105001003",
                "children": null
            }, {
                "label": "祁连路西社区居民委员会",
                "value": "630105001004",
                "children": null
            }, {
                "label": "北山社区居民委员会",
                "value": "630105001005",
                "children": null
            }, {
                "label": "北园村",
                "value": "630105001200",
                "children": null
            }, {
                "label": "朝阳村",
                "value": "630105001201",
                "children": null
            }, {
                "label": "祁家城村",
                "value": "630105001202",
                "children": null
            }, {
                "label": "寺台子村",
                "value": "630105001203",
                "children": null
            }, {
                "label": "新民村",
                "value": "630105001204",
                "children": null
            }]
        }, {
            "label": "小桥大街街道办事处",
            "value": "630105002000",
            "children": [{
                "label": "建设巷社区居民委员会",
                "value": "630105002001",
                "children": null
            }, {
                "label": "新海桥社区居民委员会",
                "value": "630105002002",
                "children": null
            }, {
                "label": "毛胜寺社区居民委员会",
                "value": "630105002003",
                "children": null
            }, {
                "label": "小桥社区居民委员会",
                "value": "630105002004",
                "children": null
            }, {
                "label": "新世纪社区居民委员会",
                "value": "630105002005",
                "children": null
            }, {
                "label": "毛胜寺村",
                "value": "630105002200",
                "children": null
            }, {
                "label": "北杏园村",
                "value": "630105002201",
                "children": null
            }, {
                "label": "陶家寨村",
                "value": "630105002202",
                "children": null
            }, {
                "label": "陶新村",
                "value": "630105002203",
                "children": null
            }, {
                "label": "小桥村",
                "value": "630105002204",
                "children": null
            }]
        }, {
            "label": "马坊街道办事处",
            "value": "630105003000",
            "children": [{
                "label": "杏园社区居民委员会",
                "value": "630105003001",
                "children": null
            }, {
                "label": "马坊东社区居民委员会",
                "value": "630105003002",
                "children": null
            }, {
                "label": "青工社区居民委员会",
                "value": "630105003003",
                "children": null
            }, {
                "label": "汽运社区居民委员会",
                "value": "630105003004",
                "children": null
            }, {
                "label": "光明社区居民委员会",
                "value": "630105003005",
                "children": null
            }, {
                "label": "新村社区居民委员会",
                "value": "630105003006",
                "children": null
            }, {
                "label": "欣乐社区居民委员会",
                "value": "630105003007",
                "children": null
            }, {
                "label": "幸福社区居民委员会",
                "value": "630105003008",
                "children": null
            }, {
                "label": "西杏园村",
                "value": "630105003200",
                "children": null
            }, {
                "label": "马坊村",
                "value": "630105003201",
                "children": null
            }, {
                "label": "盐庄村",
                "value": "630105003202",
                "children": null
            }, {
                "label": "三其村",
                "value": "630105003203",
                "children": null
            }]
        }, {
            "label": "火车西站办事处",
            "value": "630105004000",
            "children": [{
                "label": "萨尔斯堡社区",
                "value": "630105004001",
                "children": null
            }, {
                "label": "美丽水街社区",
                "value": "630105004002",
                "children": null
            }, {
                "label": "湟水河畔社区",
                "value": "630105004003",
                "children": null
            }, {
                "label": "火车西站社区",
                "value": "630105004004",
                "children": null
            }, {
                "label": "盐庄社区",
                "value": "630105004005",
                "children": null
            }]
        }, {
            "label": "大堡子镇",
            "value": "630105100000",
            "children": [{
                "label": "一机床社区居民委员会",
                "value": "630105100001",
                "children": null
            }, {
                "label": "工具厂社区居民委员会",
                "value": "630105100002",
                "children": null
            }, {
                "label": "大堡子村",
                "value": "630105100200",
                "children": null
            }, {
                "label": "严小村",
                "value": "630105100201",
                "children": null
            }, {
                "label": "宋家寨村",
                "value": "630105100202",
                "children": null
            }, {
                "label": "晋家湾村",
                "value": "630105100203",
                "children": null
            }, {
                "label": "鲍家寨村",
                "value": "630105100204",
                "children": null
            }, {
                "label": "朱南村",
                "value": "630105100205",
                "children": null
            }, {
                "label": "朱北村",
                "value": "630105100206",
                "children": null
            }, {
                "label": "吧浪村",
                "value": "630105100207",
                "children": null
            }, {
                "label": "吴仲村",
                "value": "630105100208",
                "children": null
            }, {
                "label": "汪家寨村",
                "value": "630105100209",
                "children": null
            }, {
                "label": "乙其寨村",
                "value": "630105100210",
                "children": null
            }, {
                "label": "陶南村",
                "value": "630105100211",
                "children": null
            }, {
                "label": "陶北村",
                "value": "630105100212",
                "children": null
            }]
        }, {
            "label": "廿里铺镇",
            "value": "630105101000",
            "children": [{
                "label": "生物园社区居民委员会",
                "value": "630105101001",
                "children": null
            }, {
                "label": "泉湾社区居民委员会",
                "value": "630105101002",
                "children": null
            }, {
                "label": "廿里铺村",
                "value": "630105101200",
                "children": null
            }, {
                "label": "花园台村",
                "value": "630105101201",
                "children": null
            }, {
                "label": "孙家寨村",
                "value": "630105101202",
                "children": null
            }, {
                "label": "小寨村",
                "value": "630105101203",
                "children": null
            }, {
                "label": "莫家庄村",
                "value": "630105101204",
                "children": null
            }, {
                "label": "新村",
                "value": "630105101205",
                "children": null
            }, {
                "label": "石头磊村",
                "value": "630105101206",
                "children": null
            }, {
                "label": "魏家庄村",
                "value": "630105101207",
                "children": null
            }, {
                "label": "九家湾村",
                "value": "630105101208",
                "children": null
            }, {
                "label": "郭家塔村",
                "value": "630105101209",
                "children": null
            }, {
                "label": "双苏堡村",
                "value": "630105101210",
                "children": null
            }]
        }, {
            "label": "生物科技产业园",
            "value": "630105400000",
            "children": [{
                "label": "生物科技产业园虚拟社区",
                "value": "630105400498",
                "children": null
            }]
        }]
    }, {
        "label": "大通回族土族自治县",
        "value": "630121000000",
        "children": [{
            "label": "桥头镇",
            "value": "630121100000",
            "children": [{
                "label": "人民路南",
                "value": "630121100001",
                "children": null
            }, {
                "label": "人民路北",
                "value": "630121100002",
                "children": null
            }, {
                "label": "园林路北",
                "value": "630121100003",
                "children": null
            }, {
                "label": "园林路南",
                "value": "630121100005",
                "children": null
            }, {
                "label": "解放路北",
                "value": "630121100006",
                "children": null
            }, {
                "label": "解放路南",
                "value": "630121100007",
                "children": null
            }, {
                "label": "元朔",
                "value": "630121100008",
                "children": null
            }, {
                "label": "桥电",
                "value": "630121100009",
                "children": null
            }, {
                "label": "小石山",
                "value": "630121100010",
                "children": null
            }, {
                "label": "矿山",
                "value": "630121100011",
                "children": null
            }, {
                "label": "八一",
                "value": "630121100012",
                "children": null
            }, {
                "label": "大煤洞村",
                "value": "630121100200",
                "children": null
            }, {
                "label": "元树尔村",
                "value": "630121100201",
                "children": null
            }, {
                "label": "安门滩村",
                "value": "630121100202",
                "children": null
            }, {
                "label": "胡基沟村",
                "value": "630121100203",
                "children": null
            }, {
                "label": "小煤洞村",
                "value": "630121100204",
                "children": null
            }, {
                "label": "向阳堡村",
                "value": "630121100205",
                "children": null
            }, {
                "label": "红河限村",
                "value": "630121100206",
                "children": null
            }, {
                "label": "毛家寨村",
                "value": "630121100207",
                "children": null
            }, {
                "label": "老营庄村",
                "value": "630121100208",
                "children": null
            }, {
                "label": "大湾村",
                "value": "630121100209",
                "children": null
            }, {
                "label": "水泉湾村",
                "value": "630121100210",
                "children": null
            }, {
                "label": "毛家沟村",
                "value": "630121100211",
                "children": null
            }, {
                "label": "贺家寨村",
                "value": "630121100212",
                "children": null
            }, {
                "label": "古城村",
                "value": "630121100213",
                "children": null
            }, {
                "label": "后庄村",
                "value": "630121100214",
                "children": null
            }, {
                "label": "后子沟村",
                "value": "630121100215",
                "children": null
            }, {
                "label": "南关村",
                "value": "630121100216",
                "children": null
            }, {
                "label": "上关村",
                "value": "630121100217",
                "children": null
            }, {
                "label": "上庙沟村",
                "value": "630121100218",
                "children": null
            }, {
                "label": "下庙沟村",
                "value": "630121100219",
                "children": null
            }, {
                "label": "新城村",
                "value": "630121100220",
                "children": null
            }, {
                "label": "窑庄村",
                "value": "630121100221",
                "children": null
            }]
        }, {
            "label": "城关镇",
            "value": "630121101000",
            "children": [{
                "label": "佰胜",
                "value": "630121101001",
                "children": null
            }, {
                "label": "西关村",
                "value": "630121101200",
                "children": null
            }, {
                "label": "上毛佰胜村",
                "value": "630121101201",
                "children": null
            }, {
                "label": "下毛佰胜村",
                "value": "630121101202",
                "children": null
            }, {
                "label": "西门村",
                "value": "630121101203",
                "children": null
            }, {
                "label": "城关村",
                "value": "630121101204",
                "children": null
            }, {
                "label": "东门村",
                "value": "630121101205",
                "children": null
            }, {
                "label": "好来村",
                "value": "630121101206",
                "children": null
            }, {
                "label": "龙曲村",
                "value": "630121101207",
                "children": null
            }, {
                "label": "塔哇村",
                "value": "630121101208",
                "children": null
            }, {
                "label": "贝寺村",
                "value": "630121101209",
                "children": null
            }, {
                "label": "张家庄村",
                "value": "630121101210",
                "children": null
            }, {
                "label": "大庄村",
                "value": "630121101211",
                "children": null
            }, {
                "label": "阳坡庄村",
                "value": "630121101212",
                "children": null
            }, {
                "label": "柳树庄村",
                "value": "630121101213",
                "children": null
            }, {
                "label": "李家磨村",
                "value": "630121101214",
                "children": null
            }, {
                "label": "下寺咀村",
                "value": "630121101215",
                "children": null
            }, {
                "label": "上寺咀村",
                "value": "630121101216",
                "children": null
            }, {
                "label": "沙巴图村",
                "value": "630121101217",
                "children": null
            }, {
                "label": "林家台村",
                "value": "630121101218",
                "children": null
            }, {
                "label": "铁家庄村",
                "value": "630121101219",
                "children": null
            }]
        }, {
            "label": "塔尔镇",
            "value": "630121102000",
            "children": [{
                "label": "塔尔湾",
                "value": "630121102001",
                "children": null
            }, {
                "label": "塔尔村",
                "value": "630121102200",
                "children": null
            }, {
                "label": "奔康滩村",
                "value": "630121102201",
                "children": null
            }, {
                "label": "上旧庄村",
                "value": "630121102202",
                "children": null
            }, {
                "label": "石家庄村",
                "value": "630121102203",
                "children": null
            }, {
                "label": "下旧庄村",
                "value": "630121102204",
                "children": null
            }, {
                "label": "中庄村",
                "value": "630121102205",
                "children": null
            }, {
                "label": "泉沟村",
                "value": "630121102206",
                "children": null
            }, {
                "label": "凉州庄村",
                "value": "630121102207",
                "children": null
            }, {
                "label": "河州庄村",
                "value": "630121102208",
                "children": null
            }, {
                "label": "韭菜沟村",
                "value": "630121102209",
                "children": null
            }, {
                "label": "半沟村",
                "value": "630121102210",
                "children": null
            }, {
                "label": "东药草滩村",
                "value": "630121102211",
                "children": null
            }, {
                "label": "格达庄村",
                "value": "630121102212",
                "children": null
            }, {
                "label": "塔尔沟村",
                "value": "630121102213",
                "children": null
            }, {
                "label": "王庄村",
                "value": "630121102214",
                "children": null
            }, {
                "label": "西药草滩村",
                "value": "630121102215",
                "children": null
            }]
        }, {
            "label": "东峡镇",
            "value": "630121103000",
            "children": [{
                "label": "衙门庄",
                "value": "630121103001",
                "children": null
            }, {
                "label": "衙门庄村",
                "value": "630121103200",
                "children": null
            }, {
                "label": "南滩村",
                "value": "630121103201",
                "children": null
            }, {
                "label": "麻其村",
                "value": "630121103202",
                "children": null
            }, {
                "label": "克麻尔村",
                "value": "630121103203",
                "children": null
            }, {
                "label": "杏花庄村",
                "value": "630121103204",
                "children": null
            }, {
                "label": "仙米村",
                "value": "630121103205",
                "children": null
            }, {
                "label": "田家沟村",
                "value": "630121103206",
                "children": null
            }, {
                "label": "尔麻村",
                "value": "630121103207",
                "children": null
            }, {
                "label": "康乐村",
                "value": "630121103208",
                "children": null
            }, {
                "label": "老虎沟村",
                "value": "630121103209",
                "children": null
            }, {
                "label": "刘家庄村",
                "value": "630121103210",
                "children": null
            }, {
                "label": "多隆村",
                "value": "630121103211",
                "children": null
            }, {
                "label": "元墩子村",
                "value": "630121103212",
                "children": null
            }]
        }, {
            "label": "黄家寨镇",
            "value": "630121104000",
            "children": [{
                "label": "黄东",
                "value": "630121104001",
                "children": null
            }, {
                "label": "青铝",
                "value": "630121104002",
                "children": null
            }, {
                "label": "东柳村",
                "value": "630121104200",
                "children": null
            }, {
                "label": "黄东村",
                "value": "630121104201",
                "children": null
            }, {
                "label": "黄西村",
                "value": "630121104202",
                "children": null
            }, {
                "label": "上陶村",
                "value": "630121104203",
                "children": null
            }, {
                "label": "下陶村",
                "value": "630121104204",
                "children": null
            }, {
                "label": "上赵村",
                "value": "630121104205",
                "children": null
            }, {
                "label": "下赵村",
                "value": "630121104206",
                "children": null
            }, {
                "label": "许家寨村",
                "value": "630121104207",
                "children": null
            }, {
                "label": "杨家寨村",
                "value": "630121104208",
                "children": null
            }, {
                "label": "阿家村",
                "value": "630121104209",
                "children": null
            }, {
                "label": "陈家村",
                "value": "630121104210",
                "children": null
            }, {
                "label": "大哈门村",
                "value": "630121104211",
                "children": null
            }, {
                "label": "平地庄村",
                "value": "630121104212",
                "children": null
            }, {
                "label": "平乐村",
                "value": "630121104213",
                "children": null
            }, {
                "label": "上柴村",
                "value": "630121104214",
                "children": null
            }, {
                "label": "下柴村",
                "value": "630121104215",
                "children": null
            }, {
                "label": "索家村",
                "value": "630121104216",
                "children": null
            }, {
                "label": "寺尔庄村",
                "value": "630121104217",
                "children": null
            }, {
                "label": "台台村",
                "value": "630121104218",
                "children": null
            }, {
                "label": "兴太村",
                "value": "630121104219",
                "children": null
            }]
        }, {
            "label": "长宁镇",
            "value": "630121105000",
            "children": [{
                "label": "长宁",
                "value": "630121105001",
                "children": null
            }, {
                "label": "长宁村",
                "value": "630121105200",
                "children": null
            }, {
                "label": "甘沟门村",
                "value": "630121105201",
                "children": null
            }, {
                "label": "韩家山村",
                "value": "630121105202",
                "children": null
            }, {
                "label": "宋家庄村",
                "value": "630121105203",
                "children": null
            }, {
                "label": "王家庄村",
                "value": "630121105204",
                "children": null
            }, {
                "label": "下严村",
                "value": "630121105205",
                "children": null
            }, {
                "label": "新寨村",
                "value": "630121105206",
                "children": null
            }, {
                "label": "新添堡村",
                "value": "630121105207",
                "children": null
            }, {
                "label": "上堡村",
                "value": "630121105208",
                "children": null
            }, {
                "label": "鲍西村",
                "value": "630121105209",
                "children": null
            }, {
                "label": "鲍东村",
                "value": "630121105210",
                "children": null
            }, {
                "label": "陈家庄村",
                "value": "630121105211",
                "children": null
            }, {
                "label": "代家庄村",
                "value": "630121105212",
                "children": null
            }, {
                "label": "东村村民委员会",
                "value": "630121105213",
                "children": null
            }, {
                "label": "河滩村",
                "value": "630121105214",
                "children": null
            }, {
                "label": "红崖村",
                "value": "630121105215",
                "children": null
            }, {
                "label": "康家村",
                "value": "630121105216",
                "children": null
            }, {
                "label": "上孙家寨村",
                "value": "630121105217",
                "children": null
            }, {
                "label": "双庙村",
                "value": "630121105218",
                "children": null
            }, {
                "label": "田家村",
                "value": "630121105219",
                "children": null
            }, {
                "label": "汪家村",
                "value": "630121105220",
                "children": null
            }, {
                "label": "殷家村",
                "value": "630121105221",
                "children": null
            }, {
                "label": "西村村民委员会",
                "value": "630121105222",
                "children": null
            }, {
                "label": "中咀村",
                "value": "630121105223",
                "children": null
            }, {
                "label": "尕庄村",
                "value": "630121105224",
                "children": null
            }]
        }, {
            "label": "景阳镇",
            "value": "630121106000",
            "children": [{
                "label": "景阳",
                "value": "630121106001",
                "children": null
            }, {
                "label": "大寺村",
                "value": "630121106200",
                "children": null
            }, {
                "label": "小寺村",
                "value": "630121106201",
                "children": null
            }, {
                "label": "甘树湾村",
                "value": "630121106202",
                "children": null
            }, {
                "label": "后山村",
                "value": "630121106203",
                "children": null
            }, {
                "label": "山城村",
                "value": "630121106204",
                "children": null
            }, {
                "label": "什家村",
                "value": "630121106205",
                "children": null
            }, {
                "label": "寺沟村",
                "value": "630121106206",
                "children": null
            }, {
                "label": "苏家堡村",
                "value": "630121106207",
                "children": null
            }, {
                "label": "土关村",
                "value": "630121106208",
                "children": null
            }, {
                "label": "大寨村",
                "value": "630121106209",
                "children": null
            }, {
                "label": "小寨村",
                "value": "630121106210",
                "children": null
            }, {
                "label": "哈门村",
                "value": "630121106211",
                "children": null
            }, {
                "label": "金冲村",
                "value": "630121106212",
                "children": null
            }, {
                "label": "兰冲村",
                "value": "630121106213",
                "children": null
            }, {
                "label": "龙泉村",
                "value": "630121106214",
                "children": null
            }, {
                "label": "泉头村",
                "value": "630121106215",
                "children": null
            }, {
                "label": "上岗冲村",
                "value": "630121106216",
                "children": null
            }, {
                "label": "下岗冲村",
                "value": "630121106217",
                "children": null
            }, {
                "label": "中岭村",
                "value": "630121106218",
                "children": null
            }]
        }, {
            "label": "多林镇",
            "value": "630121107000",
            "children": [{
                "label": "哈州",
                "value": "630121107001",
                "children": null
            }, {
                "label": "藏龙庄村",
                "value": "630121107200",
                "children": null
            }, {
                "label": "哈州村",
                "value": "630121107201",
                "children": null
            }, {
                "label": "口子庄村",
                "value": "630121107202",
                "children": null
            }, {
                "label": "马场庄村",
                "value": "630121107203",
                "children": null
            }, {
                "label": "上宽村",
                "value": "630121107204",
                "children": null
            }, {
                "label": "上浪加村",
                "value": "630121107205",
                "children": null
            }, {
                "label": "石头滩村",
                "value": "630121107206",
                "children": null
            }, {
                "label": "吴什庄村",
                "value": "630121107207",
                "children": null
            }, {
                "label": "下宽村",
                "value": "630121107208",
                "children": null
            }, {
                "label": "下浪加村",
                "value": "630121107209",
                "children": null
            }]
        }, {
            "label": "新庄镇",
            "value": "630121108000",
            "children": [{
                "label": "新庄",
                "value": "630121108001",
                "children": null
            }, {
                "label": "新庄村",
                "value": "630121108200",
                "children": null
            }, {
                "label": "申哇村",
                "value": "630121108201",
                "children": null
            }, {
                "label": "硖门村",
                "value": "630121108202",
                "children": null
            }, {
                "label": "台其庄村",
                "value": "630121108203",
                "children": null
            }, {
                "label": "尕庄村",
                "value": "630121108204",
                "children": null
            }, {
                "label": "中滩村",
                "value": "630121108205",
                "children": null
            }, {
                "label": "吉仓村",
                "value": "630121108206",
                "children": null
            }, {
                "label": "兰隆村",
                "value": "630121108207",
                "children": null
            }, {
                "label": "红石崖村",
                "value": "630121108208",
                "children": null
            }, {
                "label": "下山村",
                "value": "630121108209",
                "children": null
            }, {
                "label": "上山村",
                "value": "630121108210",
                "children": null
            }, {
                "label": "李家山村",
                "value": "630121108211",
                "children": null
            }]
        }, {
            "label": "青林乡",
            "value": "630121200000",
            "children": [{
                "label": "上阳山村",
                "value": "630121200200",
                "children": null
            }, {
                "label": "下阳山村",
                "value": "630121200201",
                "children": null
            }, {
                "label": "生地村",
                "value": "630121200202",
                "children": null
            }, {
                "label": "毛合湾村",
                "value": "630121200203",
                "children": null
            }, {
                "label": "卧马村",
                "value": "630121200204",
                "children": null
            }, {
                "label": "雪里合村",
                "value": "630121200205",
                "children": null
            }, {
                "label": "中庄沟村",
                "value": "630121200206",
                "children": null
            }, {
                "label": "麻哈村",
                "value": "630121200207",
                "children": null
            }, {
                "label": "棉格勒村",
                "value": "630121200208",
                "children": null
            }, {
                "label": "白土垭豁村",
                "value": "630121200209",
                "children": null
            }, {
                "label": "全家湾村",
                "value": "630121200210",
                "children": null
            }, {
                "label": "柳林滩村",
                "value": "630121200211",
                "children": null
            }]
        }, {
            "label": "青山乡",
            "value": "630121201000",
            "children": [{
                "label": "贺家庄村",
                "value": "630121201200",
                "children": null
            }, {
                "label": "作士图村",
                "value": "630121201201",
                "children": null
            }, {
                "label": "龙卧村",
                "value": "630121201202",
                "children": null
            }, {
                "label": "聂家沟村",
                "value": "630121201203",
                "children": null
            }, {
                "label": "利顺庄村",
                "value": "630121201204",
                "children": null
            }, {
                "label": "青山村",
                "value": "630121201205",
                "children": null
            }, {
                "label": "沙岱村",
                "value": "630121201206",
                "children": null
            }, {
                "label": "红泉村",
                "value": "630121201207",
                "children": null
            }, {
                "label": "沙尕图村",
                "value": "630121201208",
                "children": null
            }, {
                "label": "东山村",
                "value": "630121201209",
                "children": null
            }, {
                "label": "关巴村",
                "value": "630121201210",
                "children": null
            }, {
                "label": "多兰村",
                "value": "630121201211",
                "children": null
            }, {
                "label": "西山村",
                "value": "630121201212",
                "children": null
            }, {
                "label": "古娄马场村",
                "value": "630121201213",
                "children": null
            }, {
                "label": "西北岔村",
                "value": "630121201214",
                "children": null
            }, {
                "label": "生地乙卡村",
                "value": "630121201215",
                "children": null
            }]
        }, {
            "label": "逊让乡",
            "value": "630121202000",
            "children": [{
                "label": "逊布村",
                "value": "630121202200",
                "children": null
            }, {
                "label": "塘坊村",
                "value": "630121202201",
                "children": null
            }, {
                "label": "尕漏村",
                "value": "630121202202",
                "children": null
            }, {
                "label": "上后拉村",
                "value": "630121202203",
                "children": null
            }, {
                "label": "武胜沟村",
                "value": "630121202204",
                "children": null
            }, {
                "label": "后拉村",
                "value": "630121202205",
                "children": null
            }, {
                "label": "兰家村",
                "value": "630121202206",
                "children": null
            }, {
                "label": "安宁滩村",
                "value": "630121202207",
                "children": null
            }, {
                "label": "逊布沟村",
                "value": "630121202208",
                "children": null
            }, {
                "label": "庄头村",
                "value": "630121202209",
                "children": null
            }, {
                "label": "八里村",
                "value": "630121202210",
                "children": null
            }, {
                "label": "谷古家村",
                "value": "630121202211",
                "children": null
            }, {
                "label": "麻什藏村",
                "value": "630121202212",
                "children": null
            }]
        }, {
            "label": "极乐乡",
            "value": "630121203000",
            "children": [{
                "label": "极拉上庄村",
                "value": "630121203200",
                "children": null
            }, {
                "label": "极拉下庄村",
                "value": "630121203201",
                "children": null
            }, {
                "label": "极拉口村",
                "value": "630121203202",
                "children": null
            }, {
                "label": "下和衷村",
                "value": "630121203203",
                "children": null
            }, {
                "label": "上和衷村",
                "value": "630121203204",
                "children": null
            }, {
                "label": "阳坡村",
                "value": "630121203205",
                "children": null
            }, {
                "label": "宗阳沟村",
                "value": "630121203206",
                "children": null
            }, {
                "label": "深沟村",
                "value": "630121203207",
                "children": null
            }, {
                "label": "极拉后庄村",
                "value": "630121203208",
                "children": null
            }, {
                "label": "崖湾村",
                "value": "630121203209",
                "children": null
            }, {
                "label": "岔水坝村",
                "value": "630121203210",
                "children": null
            }]
        }, {
            "label": "石山乡",
            "value": "630121204000",
            "children": [{
                "label": "铧尖村",
                "value": "630121204200",
                "children": null
            }, {
                "label": "冰沟村",
                "value": "630121204201",
                "children": null
            }, {
                "label": "红垭豁村",
                "value": "630121204202",
                "children": null
            }, {
                "label": "上丰积村",
                "value": "630121204203",
                "children": null
            }, {
                "label": "下丰积村",
                "value": "630121204204",
                "children": null
            }, {
                "label": "石板滩村",
                "value": "630121204205",
                "children": null
            }, {
                "label": "西坡村",
                "value": "630121204206",
                "children": null
            }, {
                "label": "小沟村",
                "value": "630121204207",
                "children": null
            }, {
                "label": "杂户村",
                "value": "630121204208",
                "children": null
            }]
        }, {
            "label": "宝库乡",
            "value": "630121205000",
            "children": [{
                "label": "牛场",
                "value": "630121205001",
                "children": null
            }, {
                "label": "油房庄村",
                "value": "630121205200",
                "children": null
            }, {
                "label": "巴彦村牧民委员会",
                "value": "630121205201",
                "children": null
            }, {
                "label": "俄博图村",
                "value": "630121205202",
                "children": null
            }, {
                "label": "寺堂村",
                "value": "630121205203",
                "children": null
            }, {
                "label": "孔家梁村",
                "value": "630121205204",
                "children": null
            }, {
                "label": "五间房村",
                "value": "630121205205",
                "children": null
            }, {
                "label": "张家滩村",
                "value": "630121205206",
                "children": null
            }, {
                "label": "纳楞沟村",
                "value": "630121205207",
                "children": null
            }, {
                "label": "哈家咀村",
                "value": "630121205208",
                "children": null
            }, {
                "label": "水草滩村",
                "value": "630121205209",
                "children": null
            }, {
                "label": "祁汉沟村",
                "value": "630121205210",
                "children": null
            }]
        }, {
            "label": "斜沟乡",
            "value": "630121206000",
            "children": [{
                "label": "河滩村",
                "value": "630121206200",
                "children": null
            }, {
                "label": "大业坝村",
                "value": "630121206201",
                "children": null
            }, {
                "label": "小业坝村",
                "value": "630121206202",
                "children": null
            }, {
                "label": "斜沟村",
                "value": "630121206203",
                "children": null
            }, {
                "label": "上窑洞庄村",
                "value": "630121206204",
                "children": null
            }, {
                "label": "下窑洞庄村",
                "value": "630121206205",
                "children": null
            }, {
                "label": "业坝台村",
                "value": "630121206206",
                "children": null
            }]
        }, {
            "label": "良教乡",
            "value": "630121207000",
            "children": [{
                "label": "下治泉村",
                "value": "630121207200",
                "children": null
            }, {
                "label": "上治泉村",
                "value": "630121207201",
                "children": null
            }, {
                "label": "桥尔沟村",
                "value": "630121207202",
                "children": null
            }, {
                "label": "前跃村",
                "value": "630121207203",
                "children": null
            }, {
                "label": "良教沟村",
                "value": "630121207204",
                "children": null
            }, {
                "label": "白崖村",
                "value": "630121207205",
                "children": null
            }, {
                "label": "下甘沟村",
                "value": "630121207206",
                "children": null
            }, {
                "label": "上甘沟村",
                "value": "630121207207",
                "children": null
            }, {
                "label": "松林村",
                "value": "630121207208",
                "children": null
            }, {
                "label": "沙布村",
                "value": "630121207209",
                "children": null
            }, {
                "label": "石庄村",
                "value": "630121207210",
                "children": null
            }, {
                "label": "煤洞沟村",
                "value": "630121207211",
                "children": null
            }]
        }, {
            "label": "向化藏族乡",
            "value": "630121208000",
            "children": [{
                "label": "流水口村",
                "value": "630121208200",
                "children": null
            }, {
                "label": "达隆村",
                "value": "630121208201",
                "children": null
            }, {
                "label": "立树尔村",
                "value": "630121208202",
                "children": null
            }, {
                "label": "将军沟村",
                "value": "630121208203",
                "children": null
            }, {
                "label": "麻庄村",
                "value": "630121208204",
                "children": null
            }, {
                "label": "三角城村",
                "value": "630121208205",
                "children": null
            }, {
                "label": "上滩村",
                "value": "630121208206",
                "children": null
            }, {
                "label": "下滩村",
                "value": "630121208207",
                "children": null
            }, {
                "label": "驿卡村",
                "value": "630121208208",
                "children": null
            }]
        }, {
            "label": "桦林乡",
            "value": "630121209000",
            "children": [{
                "label": "胜利村",
                "value": "630121209200",
                "children": null
            }, {
                "label": "阿家沟村",
                "value": "630121209201",
                "children": null
            }, {
                "label": "瓜拉大庄村",
                "value": "630121209202",
                "children": null
            }, {
                "label": "东庄村",
                "value": "630121209203",
                "children": null
            }, {
                "label": "俄博沟村",
                "value": "630121209204",
                "children": null
            }, {
                "label": "关巴村",
                "value": "630121209205",
                "children": null
            }, {
                "label": "吕顺村",
                "value": "630121209206",
                "children": null
            }, {
                "label": "七棵树村",
                "value": "630121209207",
                "children": null
            }, {
                "label": "台子村",
                "value": "630121209208",
                "children": null
            }, {
                "label": "西沟村",
                "value": "630121209209",
                "children": null
            }, {
                "label": "兴隆村",
                "value": "630121209210",
                "children": null
            }, {
                "label": "桦林庄村",
                "value": "630121209211",
                "children": null
            }, {
                "label": "贲哇沟村",
                "value": "630121209212",
                "children": null
            }, {
                "label": "峡口村",
                "value": "630121209213",
                "children": null
            }]
        }, {
            "label": "朔北藏族乡",
            "value": "630121210000",
            "children": [{
                "label": "县东",
                "value": "630121210001",
                "children": null
            }, {
                "label": "阿家堡村",
                "value": "630121210200",
                "children": null
            }, {
                "label": "菜子口村",
                "value": "630121210201",
                "children": null
            }, {
                "label": "代同庄村",
                "value": "630121210202",
                "children": null
            }, {
                "label": "李家堡村",
                "value": "630121210203",
                "children": null
            }, {
                "label": "马场村",
                "value": "630121210204",
                "children": null
            }, {
                "label": "下吉哇村",
                "value": "630121210205",
                "children": null
            }, {
                "label": "小龙院村",
                "value": "630121210206",
                "children": null
            }, {
                "label": "药匠台村",
                "value": "630121210207",
                "children": null
            }, {
                "label": "永丰村",
                "value": "630121210208",
                "children": null
            }, {
                "label": "八寺崖村",
                "value": "630121210209",
                "children": null
            }, {
                "label": "白崖沟村",
                "value": "630121210210",
                "children": null
            }, {
                "label": "边麻沟村",
                "value": "630121210211",
                "children": null
            }, {
                "label": "花科庄村",
                "value": "630121210212",
                "children": null
            }, {
                "label": "东至沟村",
                "value": "630121210213",
                "children": null
            }, {
                "label": "拉浪台村",
                "value": "630121210214",
                "children": null
            }, {
                "label": "旧拉浪村",
                "value": "630121210215",
                "children": null
            }, {
                "label": "麻家庄村",
                "value": "630121210216",
                "children": null
            }, {
                "label": "郑家沟村",
                "value": "630121210217",
                "children": null
            }]
        }]
    }, {
        "label": "湟中区",
        "value": "630122000000",
        "children": [{
            "label": "康川街道办事处",
            "value": "630122001000",
            "children": [{
                "label": "下田村",
                "value": "630122001200",
                "children": null
            }, {
                "label": "羊毛村",
                "value": "630122001201",
                "children": null
            }, {
                "label": "上田村",
                "value": "630122001202",
                "children": null
            }, {
                "label": "半截沟村",
                "value": "630122001203",
                "children": null
            }, {
                "label": "杨家滩村",
                "value": "630122001204",
                "children": null
            }, {
                "label": "拉布尔村",
                "value": "630122001205",
                "children": null
            }, {
                "label": "汉东村",
                "value": "630122001206",
                "children": null
            }, {
                "label": "阴破村",
                "value": "630122001207",
                "children": null
            }, {
                "label": "上扎扎村",
                "value": "630122001208",
                "children": null
            }, {
                "label": "下扎扎村",
                "value": "630122001209",
                "children": null
            }, {
                "label": "李家庄村",
                "value": "630122001210",
                "children": null
            }, {
                "label": "下麻尔村",
                "value": "630122001211",
                "children": null
            }, {
                "label": "前窑村",
                "value": "630122001212",
                "children": null
            }, {
                "label": "孙家窑村",
                "value": "630122001213",
                "children": null
            }, {
                "label": "甘家村",
                "value": "630122001214",
                "children": null
            }, {
                "label": "大才村",
                "value": "630122001215",
                "children": null
            }]
        }, {
            "label": "鲁沙尔镇",
            "value": "630122100000",
            "children": [{
                "label": "金塔社区居民委员会",
                "value": "630122100001",
                "children": null
            }, {
                "label": "团结社区居民委员会",
                "value": "630122100002",
                "children": null
            }, {
                "label": "和平社区居民委员会",
                "value": "630122100003",
                "children": null
            }, {
                "label": "莲湖社区居民委员会",
                "value": "630122100004",
                "children": null
            }, {
                "label": "水滩村",
                "value": "630122100200",
                "children": null
            }, {
                "label": "孔家村",
                "value": "630122100201",
                "children": null
            }, {
                "label": "赵家庄村",
                "value": "630122100202",
                "children": null
            }, {
                "label": "昂藏村",
                "value": "630122100203",
                "children": null
            }, {
                "label": "和平村",
                "value": "630122100204",
                "children": null
            }, {
                "label": "河滩村",
                "value": "630122100205",
                "children": null
            }, {
                "label": "团结村",
                "value": "630122100206",
                "children": null
            }, {
                "label": "东山村",
                "value": "630122100207",
                "children": null
            }, {
                "label": "西山村",
                "value": "630122100208",
                "children": null
            }, {
                "label": "塔尔湾村",
                "value": "630122100209",
                "children": null
            }, {
                "label": "青一村",
                "value": "630122100210",
                "children": null
            }, {
                "label": "青二村",
                "value": "630122100211",
                "children": null
            }, {
                "label": "南门村",
                "value": "630122100212",
                "children": null
            }, {
                "label": "海马泉村",
                "value": "630122100213",
                "children": null
            }, {
                "label": "新村村民委员会",
                "value": "630122100214",
                "children": null
            }, {
                "label": "红崖沟村",
                "value": "630122100215",
                "children": null
            }, {
                "label": "陈家滩村",
                "value": "630122100216",
                "children": null
            }, {
                "label": "西村村民委员会",
                "value": "630122100217",
                "children": null
            }, {
                "label": "东村村民委员会",
                "value": "630122100218",
                "children": null
            }, {
                "label": "徐家寨村",
                "value": "630122100219",
                "children": null
            }, {
                "label": "石咀一村",
                "value": "630122100220",
                "children": null
            }, {
                "label": "下重台村",
                "value": "630122100221",
                "children": null
            }, {
                "label": "白土庄村",
                "value": "630122100222",
                "children": null
            }, {
                "label": "地窑村",
                "value": "630122100223",
                "children": null
            }, {
                "label": "阴坡村",
                "value": "630122100224",
                "children": null
            }, {
                "label": "阳坡村",
                "value": "630122100225",
                "children": null
            }, {
                "label": "石咀二村",
                "value": "630122100226",
                "children": null
            }, {
                "label": "吊庄村",
                "value": "630122100227",
                "children": null
            }, {
                "label": "甘河沿村",
                "value": "630122100228",
                "children": null
            }, {
                "label": "阿家庄村",
                "value": "630122100229",
                "children": null
            }, {
                "label": "朱家庄村",
                "value": "630122100230",
                "children": null
            }, {
                "label": "青石坡村",
                "value": "630122100231",
                "children": null
            }, {
                "label": "上重台村",
                "value": "630122100232",
                "children": null
            }]
        }, {
            "label": "西堡镇",
            "value": "630122101000",
            "children": [{
                "label": "西堡社区居民委员会",
                "value": "630122101001",
                "children": null
            }, {
                "label": "西堡村",
                "value": "630122101200",
                "children": null
            }, {
                "label": "佐署村",
                "value": "630122101201",
                "children": null
            }, {
                "label": "堡子村",
                "value": "630122101202",
                "children": null
            }, {
                "label": "东花园村",
                "value": "630122101203",
                "children": null
            }, {
                "label": "西花园村",
                "value": "630122101204",
                "children": null
            }, {
                "label": "羊圈村",
                "value": "630122101205",
                "children": null
            }, {
                "label": "寺尔寨村",
                "value": "630122101206",
                "children": null
            }, {
                "label": "新平村",
                "value": "630122101207",
                "children": null
            }, {
                "label": "东堡村",
                "value": "630122101208",
                "children": null
            }, {
                "label": "西两旗村",
                "value": "630122101209",
                "children": null
            }, {
                "label": "东两旗村",
                "value": "630122101210",
                "children": null
            }, {
                "label": "葛一村",
                "value": "630122101211",
                "children": null
            }, {
                "label": "葛二村",
                "value": "630122101212",
                "children": null
            }, {
                "label": "条子沟村",
                "value": "630122101213",
                "children": null
            }, {
                "label": "丰台沟村",
                "value": "630122101214",
                "children": null
            }, {
                "label": "羊圈沟村",
                "value": "630122101215",
                "children": null
            }, {
                "label": "青山村",
                "value": "630122101216",
                "children": null
            }, {
                "label": "张家尧村",
                "value": "630122101217",
                "children": null
            }, {
                "label": "鲍家沟村",
                "value": "630122101218",
                "children": null
            }]
        }, {
            "label": "上新庄镇",
            "value": "630122102000",
            "children": [{
                "label": "上新庄社区居民委员会",
                "value": "630122102001",
                "children": null
            }, {
                "label": "刘小庄村",
                "value": "630122102200",
                "children": null
            }, {
                "label": "班麻坡村",
                "value": "630122102201",
                "children": null
            }, {
                "label": "东台村",
                "value": "630122102202",
                "children": null
            }, {
                "label": "西庄村",
                "value": "630122102203",
                "children": null
            }, {
                "label": "东沟滩村",
                "value": "630122102204",
                "children": null
            }, {
                "label": "马家滩村",
                "value": "630122102205",
                "children": null
            }, {
                "label": "红牙合村",
                "value": "630122102206",
                "children": null
            }, {
                "label": "尧滩村",
                "value": "630122102207",
                "children": null
            }, {
                "label": "尧湾村",
                "value": "630122102208",
                "children": null
            }, {
                "label": "下峡门村",
                "value": "630122102209",
                "children": null
            }, {
                "label": "上峡门村",
                "value": "630122102210",
                "children": null
            }, {
                "label": "申北村",
                "value": "630122102211",
                "children": null
            }, {
                "label": "申南村",
                "value": "630122102212",
                "children": null
            }, {
                "label": "水草沟村",
                "value": "630122102213",
                "children": null
            }, {
                "label": "河滩村",
                "value": "630122102214",
                "children": null
            }, {
                "label": "黑城村",
                "value": "630122102215",
                "children": null
            }, {
                "label": "上新庄村",
                "value": "630122102216",
                "children": null
            }, {
                "label": "阳坡台村",
                "value": "630122102217",
                "children": null
            }, {
                "label": "地广村",
                "value": "630122102218",
                "children": null
            }, {
                "label": "华山村",
                "value": "630122102219",
                "children": null
            }, {
                "label": "骟马台村",
                "value": "630122102220",
                "children": null
            }, {
                "label": "加牙村",
                "value": "630122102221",
                "children": null
            }, {
                "label": "新城村",
                "value": "630122102222",
                "children": null
            }, {
                "label": "周德村",
                "value": "630122102223",
                "children": null
            }, {
                "label": "班隆村",
                "value": "630122102224",
                "children": null
            }, {
                "label": "马场村",
                "value": "630122102225",
                "children": null
            }, {
                "label": "七家庄村",
                "value": "630122102226",
                "children": null
            }, {
                "label": "海马沟村",
                "value": "630122102227",
                "children": null
            }, {
                "label": "下台村",
                "value": "630122102228",
                "children": null
            }, {
                "label": "上台村",
                "value": "630122102229",
                "children": null
            }, {
                "label": "白路尔村",
                "value": "630122102230",
                "children": null
            }, {
                "label": "白石头村",
                "value": "630122102231",
                "children": null
            }, {
                "label": "静房村",
                "value": "630122102232",
                "children": null
            }]
        }, {
            "label": "田家寨镇",
            "value": "630122103000",
            "children": [{
                "label": "田家寨社区居民委员会",
                "value": "630122103001",
                "children": null
            }, {
                "label": "泗洱河村",
                "value": "630122103200",
                "children": null
            }, {
                "label": "谢家台村",
                "value": "630122103201",
                "children": null
            }, {
                "label": "毛家台村",
                "value": "630122103202",
                "children": null
            }, {
                "label": "田家寨村",
                "value": "630122103203",
                "children": null
            }, {
                "label": "毛一村",
                "value": "630122103204",
                "children": null
            }, {
                "label": "毛二村",
                "value": "630122103205",
                "children": null
            }, {
                "label": "河湾村",
                "value": "630122103206",
                "children": null
            }, {
                "label": "新村村民委员会",
                "value": "630122103207",
                "children": null
            }, {
                "label": "李家台村",
                "value": "630122103208",
                "children": null
            }, {
                "label": "梁家村",
                "value": "630122103209",
                "children": null
            }, {
                "label": "石沟村",
                "value": "630122103210",
                "children": null
            }, {
                "label": "谢家村",
                "value": "630122103211",
                "children": null
            }, {
                "label": "大卡阳村",
                "value": "630122103212",
                "children": null
            }, {
                "label": "小卡阳村",
                "value": "630122103213",
                "children": null
            }, {
                "label": "甘家村",
                "value": "630122103214",
                "children": null
            }, {
                "label": "公牙村",
                "value": "630122103215",
                "children": null
            }, {
                "label": "喇家村",
                "value": "630122103216",
                "children": null
            }, {
                "label": "窑洞村",
                "value": "630122103217",
                "children": null
            }, {
                "label": "下营一村",
                "value": "630122103218",
                "children": null
            }, {
                "label": "上营一村",
                "value": "630122103219",
                "children": null
            }, {
                "label": "拉尕尔村",
                "value": "630122103220",
                "children": null
            }, {
                "label": "黄蒿台村",
                "value": "630122103221",
                "children": null
            }, {
                "label": "流水沟村",
                "value": "630122103222",
                "children": null
            }, {
                "label": "群塔村",
                "value": "630122103223",
                "children": null
            }, {
                "label": "阳坡一村",
                "value": "630122103224",
                "children": null
            }, {
                "label": "鸽堂村",
                "value": "630122103225",
                "children": null
            }, {
                "label": "丹麻村",
                "value": "630122103226",
                "children": null
            }, {
                "label": "坪台村",
                "value": "630122103227",
                "children": null
            }, {
                "label": "永丰村",
                "value": "630122103228",
                "children": null
            }, {
                "label": "安宁村",
                "value": "630122103229",
                "children": null
            }, {
                "label": "李家庄村",
                "value": "630122103230",
                "children": null
            }, {
                "label": "阳坡二村",
                "value": "630122103231",
                "children": null
            }, {
                "label": "阴坡村",
                "value": "630122103232",
                "children": null
            }, {
                "label": "尕院尔村",
                "value": "630122103233",
                "children": null
            }, {
                "label": "上营二村",
                "value": "630122103234",
                "children": null
            }, {
                "label": "下营二村",
                "value": "630122103235",
                "children": null
            }, {
                "label": "卜家台村",
                "value": "630122103236",
                "children": null
            }, {
                "label": "台口子村",
                "value": "630122103237",
                "children": null
            }, {
                "label": "沙尔湾村",
                "value": "630122103238",
                "children": null
            }, {
                "label": "上洛麻村",
                "value": "630122103239",
                "children": null
            }, {
                "label": "下洛麻村",
                "value": "630122103240",
                "children": null
            }, {
                "label": "鲍家村",
                "value": "630122103241",
                "children": null
            }, {
                "label": "马昌沟村",
                "value": "630122103242",
                "children": null
            }]
        }, {
            "label": "甘河滩镇",
            "value": "630122104000",
            "children": [{
                "label": "甘河滩社区居民委员会",
                "value": "630122104001",
                "children": null
            }, {
                "label": "甘河村",
                "value": "630122104200",
                "children": null
            }, {
                "label": "页沟村",
                "value": "630122104201",
                "children": null
            }, {
                "label": "坡东村",
                "value": "630122104202",
                "children": null
            }, {
                "label": "坡西村",
                "value": "630122104203",
                "children": null
            }, {
                "label": "隆寺干村",
                "value": "630122104204",
                "children": null
            }, {
                "label": "下中沟村",
                "value": "630122104205",
                "children": null
            }, {
                "label": "上中沟村",
                "value": "630122104206",
                "children": null
            }, {
                "label": "元山尔村",
                "value": "630122104207",
                "children": null
            }, {
                "label": "卡跃村",
                "value": "630122104208",
                "children": null
            }, {
                "label": "上营村",
                "value": "630122104209",
                "children": null
            }, {
                "label": "下营村",
                "value": "630122104210",
                "children": null
            }, {
                "label": "上河湾村",
                "value": "630122104211",
                "children": null
            }, {
                "label": "下河湾村",
                "value": "630122104212",
                "children": null
            }, {
                "label": "李九村",
                "value": "630122104213",
                "children": null
            }, {
                "label": "前跃村",
                "value": "630122104214",
                "children": null
            }, {
                "label": "东湾村",
                "value": "630122104215",
                "children": null
            }, {
                "label": "黄一村",
                "value": "630122104216",
                "children": null
            }, {
                "label": "黄二村",
                "value": "630122104217",
                "children": null
            }]
        }, {
            "label": "共和镇",
            "value": "630122105000",
            "children": [{
                "label": "共和社区居民委员会",
                "value": "630122105001",
                "children": null
            }, {
                "label": "北村村民委员会",
                "value": "630122105200",
                "children": null
            }, {
                "label": "南村村民委员会",
                "value": "630122105201",
                "children": null
            }, {
                "label": "山甲村",
                "value": "630122105202",
                "children": null
            }, {
                "label": "石城村",
                "value": "630122105203",
                "children": null
            }, {
                "label": "后营村",
                "value": "630122105204",
                "children": null
            }, {
                "label": "前营村",
                "value": "630122105205",
                "children": null
            }, {
                "label": "木场村",
                "value": "630122105206",
                "children": null
            }, {
                "label": "上直沟村",
                "value": "630122105207",
                "children": null
            }, {
                "label": "花勒城村",
                "value": "630122105208",
                "children": null
            }, {
                "label": "王家山村",
                "value": "630122105209",
                "children": null
            }, {
                "label": "苏尔吉村",
                "value": "630122105210",
                "children": null
            }, {
                "label": "转嘴村",
                "value": "630122105211",
                "children": null
            }, {
                "label": "东岔村",
                "value": "630122105212",
                "children": null
            }, {
                "label": "西岔村",
                "value": "630122105213",
                "children": null
            }, {
                "label": "盘道村",
                "value": "630122105214",
                "children": null
            }, {
                "label": "西台村",
                "value": "630122105215",
                "children": null
            }, {
                "label": "东台村",
                "value": "630122105216",
                "children": null
            }, {
                "label": "新湾村",
                "value": "630122105217",
                "children": null
            }, {
                "label": "葱湾村",
                "value": "630122105218",
                "children": null
            }, {
                "label": "达草沟村",
                "value": "630122105219",
                "children": null
            }, {
                "label": "下马申村",
                "value": "630122105220",
                "children": null
            }, {
                "label": "上马申村",
                "value": "630122105221",
                "children": null
            }, {
                "label": "河湾村",
                "value": "630122105222",
                "children": null
            }, {
                "label": "后街村",
                "value": "630122105223",
                "children": null
            }, {
                "label": "新庄村",
                "value": "630122105224",
                "children": null
            }, {
                "label": "尕庄村",
                "value": "630122105225",
                "children": null
            }, {
                "label": "庄科脑村",
                "value": "630122105226",
                "children": null
            }, {
                "label": "尖达村",
                "value": "630122105227",
                "children": null
            }, {
                "label": "萱麻湾村",
                "value": "630122105228",
                "children": null
            }, {
                "label": "押必村",
                "value": "630122105229",
                "children": null
            }]
        }, {
            "label": "多巴镇",
            "value": "630122106000",
            "children": [{
                "label": "多巴社区居民委员会",
                "value": "630122106001",
                "children": null
            }, {
                "label": "小寨村",
                "value": "630122106200",
                "children": null
            }, {
                "label": "双寨村",
                "value": "630122106201",
                "children": null
            }, {
                "label": "大崖沟村",
                "value": "630122106202",
                "children": null
            }, {
                "label": "韦家庄村",
                "value": "630122106203",
                "children": null
            }, {
                "label": "甘河门村",
                "value": "630122106204",
                "children": null
            }, {
                "label": "新墩村",
                "value": "630122106205",
                "children": null
            }, {
                "label": "康城村",
                "value": "630122106206",
                "children": null
            }, {
                "label": "城东村",
                "value": "630122106207",
                "children": null
            }, {
                "label": "城西村",
                "value": "630122106208",
                "children": null
            }, {
                "label": "王家庄村",
                "value": "630122106209",
                "children": null
            }, {
                "label": "城中村",
                "value": "630122106210",
                "children": null
            }, {
                "label": "银格达村",
                "value": "630122106211",
                "children": null
            }, {
                "label": "丰胜村",
                "value": "630122106212",
                "children": null
            }, {
                "label": "国寺营村",
                "value": "630122106213",
                "children": null
            }, {
                "label": "石板沟村",
                "value": "630122106214",
                "children": null
            }, {
                "label": "扎麻隆村",
                "value": "630122106215",
                "children": null
            }, {
                "label": "马申茂村",
                "value": "630122106216",
                "children": null
            }, {
                "label": "加拉山村",
                "value": "630122106217",
                "children": null
            }, {
                "label": "尚什家村",
                "value": "630122106218",
                "children": null
            }, {
                "label": "羊圈村",
                "value": "630122106219",
                "children": null
            }, {
                "label": "多四村",
                "value": "630122106220",
                "children": null
            }, {
                "label": "指挥庄村",
                "value": "630122106221",
                "children": null
            }, {
                "label": "多二村",
                "value": "630122106222",
                "children": null
            }, {
                "label": "燕尔沟村",
                "value": "630122106223",
                "children": null
            }, {
                "label": "大掌村",
                "value": "630122106224",
                "children": null
            }, {
                "label": "多三村",
                "value": "630122106225",
                "children": null
            }, {
                "label": "多一村",
                "value": "630122106226",
                "children": null
            }, {
                "label": "黑嘴村",
                "value": "630122106227",
                "children": null
            }, {
                "label": "沙窝尔村",
                "value": "630122106228",
                "children": null
            }, {
                "label": "幸福村",
                "value": "630122106229",
                "children": null
            }, {
                "label": "初哇村",
                "value": "630122106230",
                "children": null
            }, {
                "label": "玉拉村",
                "value": "630122106231",
                "children": null
            }, {
                "label": "合尔营村",
                "value": "630122106232",
                "children": null
            }, {
                "label": "丹麻寺村",
                "value": "630122106233",
                "children": null
            }, {
                "label": "奔巴口村",
                "value": "630122106234",
                "children": null
            }, {
                "label": "油房台村",
                "value": "630122106235",
                "children": null
            }, {
                "label": "年家庄村",
                "value": "630122106236",
                "children": null
            }, {
                "label": "杨家台村",
                "value": "630122106237",
                "children": null
            }, {
                "label": "北沟村",
                "value": "630122106238",
                "children": null
            }, {
                "label": "目尔加村",
                "value": "630122106239",
                "children": null
            }, {
                "label": "拉卡山村",
                "value": "630122106240",
                "children": null
            }, {
                "label": "尕尔加村",
                "value": "630122106241",
                "children": null
            }, {
                "label": "中村村民委员会",
                "value": "630122106242",
                "children": null
            }, {
                "label": "洛尔洞村",
                "value": "630122106243",
                "children": null
            }]
        }, {
            "label": "拦隆口镇",
            "value": "630122107000",
            "children": [{
                "label": "拦隆口社区居民委员会",
                "value": "630122107001",
                "children": null
            }, {
                "label": "新村村民委员会",
                "value": "630122107200",
                "children": null
            }, {
                "label": "扎什营村",
                "value": "630122107201",
                "children": null
            }, {
                "label": "巴达村",
                "value": "630122107202",
                "children": null
            }, {
                "label": "班仲营村",
                "value": "630122107203",
                "children": null
            }, {
                "label": "端巴营村",
                "value": "630122107204",
                "children": null
            }, {
                "label": "西岔村",
                "value": "630122107205",
                "children": null
            }, {
                "label": "下鲁尔加村",
                "value": "630122107206",
                "children": null
            }, {
                "label": "上鲁尔加村",
                "value": "630122107207",
                "children": null
            }, {
                "label": "拦隆口村",
                "value": "630122107208",
                "children": null
            }, {
                "label": "白杨口村",
                "value": "630122107209",
                "children": null
            }, {
                "label": "东拉科村",
                "value": "630122107210",
                "children": null
            }, {
                "label": "南门一村",
                "value": "630122107211",
                "children": null
            }, {
                "label": "前庄村",
                "value": "630122107212",
                "children": null
            }, {
                "label": "中庄村",
                "value": "630122107213",
                "children": null
            }, {
                "label": "上庄村",
                "value": "630122107214",
                "children": null
            }, {
                "label": "卡阳村",
                "value": "630122107215",
                "children": null
            }, {
                "label": "白崖一村",
                "value": "630122107216",
                "children": null
            }, {
                "label": "泥隆台村",
                "value": "630122107217",
                "children": null
            }, {
                "label": "泥隆口村",
                "value": "630122107218",
                "children": null
            }, {
                "label": "桥西村",
                "value": "630122107219",
                "children": null
            }, {
                "label": "千西村",
                "value": "630122107220",
                "children": null
            }, {
                "label": "千东村",
                "value": "630122107221",
                "children": null
            }, {
                "label": "铁家营村",
                "value": "630122107222",
                "children": null
            }, {
                "label": "上营村",
                "value": "630122107223",
                "children": null
            }, {
                "label": "上寺村",
                "value": "630122107224",
                "children": null
            }, {
                "label": "拦隆一村",
                "value": "630122107225",
                "children": null
            }, {
                "label": "拦隆二村",
                "value": "630122107226",
                "children": null
            }, {
                "label": "白崖二村",
                "value": "630122107227",
                "children": null
            }, {
                "label": "合尔营村",
                "value": "630122107228",
                "children": null
            }, {
                "label": "麻子营村",
                "value": "630122107229",
                "children": null
            }, {
                "label": "后河尔村",
                "value": "630122107230",
                "children": null
            }, {
                "label": "佰什营村",
                "value": "630122107231",
                "children": null
            }, {
                "label": "图巴营村",
                "value": "630122107232",
                "children": null
            }, {
                "label": "尼麻隆村",
                "value": "630122107233",
                "children": null
            }, {
                "label": "上红土沟村",
                "value": "630122107234",
                "children": null
            }, {
                "label": "下红土沟村",
                "value": "630122107235",
                "children": null
            }, {
                "label": "红林村",
                "value": "630122107236",
                "children": null
            }, {
                "label": "民族村",
                "value": "630122107237",
                "children": null
            }, {
                "label": "邦隆村",
                "value": "630122107238",
                "children": null
            }, {
                "label": "民联村",
                "value": "630122107239",
                "children": null
            }, {
                "label": "峡口村",
                "value": "630122107240",
                "children": null
            }, {
                "label": "南门二村",
                "value": "630122107241",
                "children": null
            }]
        }, {
            "label": "上五庄镇",
            "value": "630122108000",
            "children": [{
                "label": "上五庄社区居民委员会",
                "value": "630122108001",
                "children": null
            }, {
                "label": "合尔盖村",
                "value": "630122108200",
                "children": null
            }, {
                "label": "北纳村",
                "value": "630122108201",
                "children": null
            }, {
                "label": "马场村",
                "value": "630122108202",
                "children": null
            }, {
                "label": "友爱村",
                "value": "630122108203",
                "children": null
            }, {
                "label": "邦吧村",
                "value": "630122108204",
                "children": null
            }, {
                "label": "华科村",
                "value": "630122108205",
                "children": null
            }, {
                "label": "纳卜藏村",
                "value": "630122108206",
                "children": null
            }, {
                "label": "包勒村",
                "value": "630122108207",
                "children": null
            }, {
                "label": "拉斯目村",
                "value": "630122108208",
                "children": null
            }, {
                "label": "北庄村",
                "value": "630122108209",
                "children": null
            }, {
                "label": "峡口村",
                "value": "630122108210",
                "children": null
            }, {
                "label": "甫崖村",
                "value": "630122108211",
                "children": null
            }, {
                "label": "拉尔宁一村",
                "value": "630122108212",
                "children": null
            }, {
                "label": "拉尔宁二村",
                "value": "630122108213",
                "children": null
            }, {
                "label": "拉尔宁三村",
                "value": "630122108214",
                "children": null
            }, {
                "label": "黄草沟村",
                "value": "630122108215",
                "children": null
            }, {
                "label": "大寺沟一村",
                "value": "630122108216",
                "children": null
            }, {
                "label": "大寺沟二村",
                "value": "630122108217",
                "children": null
            }, {
                "label": "业宏村",
                "value": "630122108218",
                "children": null
            }, {
                "label": "拉目台村",
                "value": "630122108219",
                "children": null
            }, {
                "label": "小寺沟村",
                "value": "630122108220",
                "children": null
            }]
        }, {
            "label": "李家山镇",
            "value": "630122109000",
            "children": [{
                "label": "李家山社区居民委员会",
                "value": "630122109001",
                "children": null
            }, {
                "label": "崖头村",
                "value": "630122109200",
                "children": null
            }, {
                "label": "董家湾村",
                "value": "630122109201",
                "children": null
            }, {
                "label": "柳树庄村",
                "value": "630122109202",
                "children": null
            }, {
                "label": "王家堡村",
                "value": "630122109203",
                "children": null
            }, {
                "label": "卡约村",
                "value": "630122109204",
                "children": null
            }, {
                "label": "下坪村",
                "value": "630122109205",
                "children": null
            }, {
                "label": "上坪村",
                "value": "630122109206",
                "children": null
            }, {
                "label": "包家庄村",
                "value": "630122109207",
                "children": null
            }, {
                "label": "陈家庄村",
                "value": "630122109208",
                "children": null
            }, {
                "label": "汉水沟村",
                "value": "630122109209",
                "children": null
            }, {
                "label": "毛尔茨沟村",
                "value": "630122109210",
                "children": null
            }, {
                "label": "马营村",
                "value": "630122109211",
                "children": null
            }, {
                "label": "下油房村",
                "value": "630122109212",
                "children": null
            }, {
                "label": "纳家村",
                "value": "630122109213",
                "children": null
            }, {
                "label": "岗岔村",
                "value": "630122109214",
                "children": null
            }, {
                "label": "上西河村",
                "value": "630122109215",
                "children": null
            }, {
                "label": "下西河村",
                "value": "630122109216",
                "children": null
            }, {
                "label": "新添堡村",
                "value": "630122109217",
                "children": null
            }, {
                "label": "河湾村",
                "value": "630122109218",
                "children": null
            }, {
                "label": "吉家村",
                "value": "630122109219",
                "children": null
            }, {
                "label": "甘家村",
                "value": "630122109220",
                "children": null
            }, {
                "label": "新庄村",
                "value": "630122109221",
                "children": null
            }, {
                "label": "勺麻营村",
                "value": "630122109222",
                "children": null
            }, {
                "label": "大路村",
                "value": "630122109223",
                "children": null
            }, {
                "label": "李家山村",
                "value": "630122109224",
                "children": null
            }, {
                "label": "马圈沟村",
                "value": "630122109225",
                "children": null
            }, {
                "label": "金跃村",
                "value": "630122109226",
                "children": null
            }, {
                "label": "峡口村",
                "value": "630122109227",
                "children": null
            }, {
                "label": "阳坡村",
                "value": "630122109228",
                "children": null
            }, {
                "label": "阴坡村",
                "value": "630122109229",
                "children": null
            }, {
                "label": "恰罗村",
                "value": "630122109230",
                "children": null
            }, {
                "label": "塔尔沟村",
                "value": "630122109231",
                "children": null
            }]
        }, {
            "label": "群加乡",
            "value": "630122200000",
            "children": [{
                "label": "唐阳村",
                "value": "630122200200",
                "children": null
            }, {
                "label": "上圈村",
                "value": "630122200201",
                "children": null
            }, {
                "label": "下圈村",
                "value": "630122200202",
                "children": null
            }, {
                "label": "土康村",
                "value": "630122200203",
                "children": null
            }, {
                "label": "来路村",
                "value": "630122200204",
                "children": null
            }]
        }, {
            "label": "土门关乡",
            "value": "630122201000",
            "children": [{
                "label": "土门关村",
                "value": "630122201200",
                "children": null
            }, {
                "label": "坝沟村",
                "value": "630122201201",
                "children": null
            }, {
                "label": "坝沟门村",
                "value": "630122201202",
                "children": null
            }, {
                "label": "年坝村",
                "value": "630122201203",
                "children": null
            }, {
                "label": "林马村",
                "value": "630122201204",
                "children": null
            }, {
                "label": "后沟村",
                "value": "630122201205",
                "children": null
            }, {
                "label": "加汝尔村",
                "value": "630122201206",
                "children": null
            }, {
                "label": "下山庄村",
                "value": "630122201207",
                "children": null
            }, {
                "label": "上山庄村",
                "value": "630122201208",
                "children": null
            }, {
                "label": "红岭村",
                "value": "630122201209",
                "children": null
            }, {
                "label": "王沟尔村",
                "value": "630122201210",
                "children": null
            }, {
                "label": "贾尔藏村",
                "value": "630122201211",
                "children": null
            }, {
                "label": "关跃村",
                "value": "630122201212",
                "children": null
            }, {
                "label": "青峰村",
                "value": "630122201213",
                "children": null
            }, {
                "label": "业隆村",
                "value": "630122201214",
                "children": null
            }, {
                "label": "牙加村",
                "value": "630122201215",
                "children": null
            }, {
                "label": "秋子沟村",
                "value": "630122201216",
                "children": null
            }, {
                "label": "上阿卡村",
                "value": "630122201217",
                "children": null
            }, {
                "label": "下阿卡村",
                "value": "630122201218",
                "children": null
            }]
        }, {
            "label": "汉东乡",
            "value": "630122202000",
            "children": [{
                "label": "后尧村",
                "value": "630122202206",
                "children": null
            }, {
                "label": "冰沟村",
                "value": "630122202207",
                "children": null
            }]
        }, {
            "label": "大才乡",
            "value": "630122203000",
            "children": [{
                "label": "曲渠村",
                "value": "630122203203",
                "children": null
            }, {
                "label": "上错隆村",
                "value": "630122203204",
                "children": null
            }, {
                "label": "前沟村",
                "value": "630122203206",
                "children": null
            }, {
                "label": "中沟村",
                "value": "630122203207",
                "children": null
            }, {
                "label": "下后沟村",
                "value": "630122203208",
                "children": null
            }, {
                "label": "下白崖村",
                "value": "630122203209",
                "children": null
            }, {
                "label": "马场村",
                "value": "630122203210",
                "children": null
            }, {
                "label": "上白崖村",
                "value": "630122203211",
                "children": null
            }, {
                "label": "小沟尔村",
                "value": "630122203212",
                "children": null
            }, {
                "label": "立欠村",
                "value": "630122203213",
                "children": null
            }, {
                "label": "占林村",
                "value": "630122203214",
                "children": null
            }, {
                "label": "小磨石沟村",
                "value": "630122203215",
                "children": null
            }, {
                "label": "大磨石沟村",
                "value": "630122203216",
                "children": null
            }, {
                "label": "上后沟村",
                "value": "630122203217",
                "children": null
            }, {
                "label": "扎子村",
                "value": "630122203218",
                "children": null
            }]
        }, {
            "label": "海子沟乡",
            "value": "630122204000",
            "children": [{
                "label": "中庄村",
                "value": "630122204200",
                "children": null
            }, {
                "label": "阿滩村",
                "value": "630122204201",
                "children": null
            }, {
                "label": "普通村",
                "value": "630122204202",
                "children": null
            }, {
                "label": "陶家村",
                "value": "630122204203",
                "children": null
            }, {
                "label": "甘沟村",
                "value": "630122204204",
                "children": null
            }, {
                "label": "东沟村",
                "value": "630122204205",
                "children": null
            }, {
                "label": "总堡村",
                "value": "630122204206",
                "children": null
            }, {
                "label": "景家庄村",
                "value": "630122204207",
                "children": null
            }, {
                "label": "万家坪村",
                "value": "630122204208",
                "children": null
            }, {
                "label": "水滩村",
                "value": "630122204209",
                "children": null
            }, {
                "label": "杨库托村",
                "value": "630122204210",
                "children": null
            }, {
                "label": "王家庄村",
                "value": "630122204211",
                "children": null
            }, {
                "label": "沟脑村",
                "value": "630122204212",
                "children": null
            }, {
                "label": "海南庄村",
                "value": "630122204213",
                "children": null
            }, {
                "label": "古城沟村",
                "value": "630122204214",
                "children": null
            }, {
                "label": "东沟脑村",
                "value": "630122204215",
                "children": null
            }, {
                "label": "黑沟村",
                "value": "630122204216",
                "children": null
            }, {
                "label": "松家沟村",
                "value": "630122204217",
                "children": null
            }, {
                "label": "顾家岭村",
                "value": "630122204218",
                "children": null
            }, {
                "label": "薛姓庄村",
                "value": "630122204219",
                "children": null
            }, {
                "label": "大有山村",
                "value": "630122204220",
                "children": null
            }]
        }, {
            "label": "甘河工业园",
            "value": "630122400000",
            "children": [{
                "label": "甘河工业园虚拟社区",
                "value": "630122400498",
                "children": null
            }]
        }]
    }, {
        "label": "湟源县",
        "value": "630123000000",
        "children": [{
            "label": "城关镇",
            "value": "630123100000",
            "children": [{
                "label": "城台社区居民委员会",
                "value": "630123100001",
                "children": null
            }, {
                "label": "人民街社区居民委员会",
                "value": "630123100002",
                "children": null
            }, {
                "label": "南小路社区居民委员会",
                "value": "630123100003",
                "children": null
            }, {
                "label": "万安街社区居民委员会",
                "value": "630123100004",
                "children": null
            }, {
                "label": "万丰社区居民委员会",
                "value": "630123100005",
                "children": null
            }, {
                "label": "西大街社区居民委员会",
                "value": "630123100006",
                "children": null
            }, {
                "label": "西关街社区居民委员会",
                "value": "630123100007",
                "children": null
            }, {
                "label": "城郊社区居民委员会",
                "value": "630123100008",
                "children": null
            }, {
                "label": "涌兴村",
                "value": "630123100200",
                "children": null
            }, {
                "label": "万丰村",
                "value": "630123100201",
                "children": null
            }, {
                "label": "纳隆口村",
                "value": "630123100202",
                "children": null
            }, {
                "label": "河拉台村",
                "value": "630123100203",
                "children": null
            }, {
                "label": "国光村",
                "value": "630123100204",
                "children": null
            }, {
                "label": "光华村",
                "value": "630123100205",
                "children": null
            }, {
                "label": "尕庄村",
                "value": "630123100206",
                "children": null
            }, {
                "label": "董家庄村",
                "value": "630123100207",
                "children": null
            }]
        }, {
            "label": "大华镇",
            "value": "630123101000",
            "children": [{
                "label": "拉拉口村",
                "value": "630123101200",
                "children": null
            }, {
                "label": "何家庄村",
                "value": "630123101201",
                "children": null
            }, {
                "label": "大华村",
                "value": "630123101202",
                "children": null
            }, {
                "label": "池汉村",
                "value": "630123101203",
                "children": null
            }, {
                "label": "新胜村",
                "value": "630123101204",
                "children": null
            }, {
                "label": "石崖庄村",
                "value": "630123101205",
                "children": null
            }, {
                "label": "三条沟村",
                "value": "630123101206",
                "children": null
            }, {
                "label": "莫布拉脑村",
                "value": "630123101207",
                "children": null
            }, {
                "label": "莫布拉村",
                "value": "630123101208",
                "children": null
            }, {
                "label": "拉桌奈村",
                "value": "630123101209",
                "children": null
            }, {
                "label": "窑洞村",
                "value": "630123101210",
                "children": null
            }, {
                "label": "湟茂村",
                "value": "630123101211",
                "children": null
            }, {
                "label": "巴汉村",
                "value": "630123101212",
                "children": null
            }, {
                "label": "塔湾村",
                "value": "630123101213",
                "children": null
            }, {
                "label": "崖根村",
                "value": "630123101214",
                "children": null
            }, {
                "label": "红土湾村",
                "value": "630123101215",
                "children": null
            }, {
                "label": "河南村",
                "value": "630123101216",
                "children": null
            }, {
                "label": "后庄村",
                "value": "630123101217",
                "children": null
            }, {
                "label": "石嘴村",
                "value": "630123101218",
                "children": null
            }, {
                "label": "巴燕吉盖村",
                "value": "630123101219",
                "children": null
            }, {
                "label": "赛尔村",
                "value": "630123101220",
                "children": null
            }, {
                "label": "托思胡村",
                "value": "630123101221",
                "children": null
            }, {
                "label": "牙麻岔村",
                "value": "630123101222",
                "children": null
            }, {
                "label": "阿家图村",
                "value": "630123101223",
                "children": null
            }, {
                "label": "纳隆沟村",
                "value": "630123101224",
                "children": null
            }]
        }, {
            "label": "东峡乡",
            "value": "630123200000",
            "children": [{
                "label": "石崖庄村",
                "value": "630123200200",
                "children": null
            }, {
                "label": "新民村",
                "value": "630123200201",
                "children": null
            }, {
                "label": "响河村",
                "value": "630123200202",
                "children": null
            }, {
                "label": "下脖项村",
                "value": "630123200203",
                "children": null
            }, {
                "label": "峨头山村",
                "value": "630123200204",
                "children": null
            }, {
                "label": "兰占巴村",
                "value": "630123200205",
                "children": null
            }, {
                "label": "灰条沟村",
                "value": "630123200206",
                "children": null
            }, {
                "label": "灰条口村",
                "value": "630123200207",
                "children": null
            }, {
                "label": "拉尔贯村",
                "value": "630123200208",
                "children": null
            }, {
                "label": "北山村",
                "value": "630123200209",
                "children": null
            }, {
                "label": "柏树堂村",
                "value": "630123200210",
                "children": null
            }, {
                "label": "山岔村",
                "value": "630123200211",
                "children": null
            }, {
                "label": "炭窑村",
                "value": "630123200212",
                "children": null
            }]
        }, {
            "label": "日月乡",
            "value": "630123201000",
            "children": [{
                "label": "兔尔干村",
                "value": "630123201200",
                "children": null
            }, {
                "label": "药水村",
                "value": "630123201201",
                "children": null
            }, {
                "label": "雪隆村",
                "value": "630123201202",
                "children": null
            }, {
                "label": "小茶石浪村",
                "value": "630123201203",
                "children": null
            }, {
                "label": "大茶石浪村",
                "value": "630123201204",
                "children": null
            }, {
                "label": "下若约村",
                "value": "630123201205",
                "children": null
            }, {
                "label": "兔尔台村",
                "value": "630123201206",
                "children": null
            }, {
                "label": "乙细村",
                "value": "630123201207",
                "children": null
            }, {
                "label": "寺滩村",
                "value": "630123201208",
                "children": null
            }, {
                "label": "上若约村",
                "value": "630123201209",
                "children": null
            }, {
                "label": "山根村",
                "value": "630123201210",
                "children": null
            }, {
                "label": "若药堂村",
                "value": "630123201211",
                "children": null
            }, {
                "label": "日月山村",
                "value": "630123201212",
                "children": null
            }, {
                "label": "前滩村",
                "value": "630123201213",
                "children": null
            }, {
                "label": "莫多吉村",
                "value": "630123201214",
                "children": null
            }, {
                "label": "克素尔村",
                "value": "630123201215",
                "children": null
            }, {
                "label": "哈城村",
                "value": "630123201216",
                "children": null
            }, {
                "label": "尕庄村",
                "value": "630123201217",
                "children": null
            }, {
                "label": "尕恰莫多村",
                "value": "630123201218",
                "children": null
            }, {
                "label": "大石头村",
                "value": "630123201219",
                "children": null
            }, {
                "label": "池汉素村",
                "value": "630123201220",
                "children": null
            }, {
                "label": "本炕村",
                "value": "630123201221",
                "children": null
            }, {
                "label": "牧场村",
                "value": "630123201222",
                "children": null
            }]
        }, {
            "label": "和平乡",
            "value": "630123202000",
            "children": [{
                "label": "茶汉素村",
                "value": "630123202200",
                "children": null
            }, {
                "label": "马场湾村",
                "value": "630123202201",
                "children": null
            }, {
                "label": "董家脑村",
                "value": "630123202202",
                "children": null
            }, {
                "label": "小高陵村",
                "value": "630123202203",
                "children": null
            }, {
                "label": "大高陵村",
                "value": "630123202204",
                "children": null
            }, {
                "label": "上拉雾台村",
                "value": "630123202205",
                "children": null
            }, {
                "label": "下拉雾台村",
                "value": "630123202206",
                "children": null
            }, {
                "label": "曲布滩村",
                "value": "630123202207",
                "children": null
            }, {
                "label": "马家湾村",
                "value": "630123202208",
                "children": null
            }, {
                "label": "马场台村",
                "value": "630123202209",
                "children": null
            }, {
                "label": "隆和村",
                "value": "630123202210",
                "children": null
            }, {
                "label": "加牙麻村",
                "value": "630123202211",
                "children": null
            }, {
                "label": "和平村",
                "value": "630123202212",
                "children": null
            }, {
                "label": "尕庄村",
                "value": "630123202213",
                "children": null
            }, {
                "label": "茶曲村",
                "value": "630123202214",
                "children": null
            }, {
                "label": "蒙古道村",
                "value": "630123202215",
                "children": null
            }, {
                "label": "草沟村",
                "value": "630123202216",
                "children": null
            }, {
                "label": "白水村",
                "value": "630123202217",
                "children": null
            }, {
                "label": "泉尔湾村",
                "value": "630123202218",
                "children": null
            }, {
                "label": "刘家台村",
                "value": "630123202219",
                "children": null
            }]
        }, {
            "label": "波航乡",
            "value": "630123203000",
            "children": [{
                "label": "波航村",
                "value": "630123203200",
                "children": null
            }, {
                "label": "纳隆村",
                "value": "630123203201",
                "children": null
            }, {
                "label": "西岔村",
                "value": "630123203202",
                "children": null
            }, {
                "label": "南岔村",
                "value": "630123203203",
                "children": null
            }, {
                "label": "石崖湾村",
                "value": "630123203204",
                "children": null
            }, {
                "label": "上台村",
                "value": "630123203205",
                "children": null
            }, {
                "label": "下台村",
                "value": "630123203206",
                "children": null
            }, {
                "label": "上泉尔村",
                "value": "630123203207",
                "children": null
            }, {
                "label": "泉尔湾村",
                "value": "630123203208",
                "children": null
            }, {
                "label": "麻尼台村",
                "value": "630123203209",
                "children": null
            }, {
                "label": "浪湾村",
                "value": "630123203210",
                "children": null
            }, {
                "label": "胡思洞村",
                "value": "630123203211",
                "children": null
            }, {
                "label": "甘沟村",
                "value": "630123203212",
                "children": null
            }]
        }, {
            "label": "申中乡",
            "value": "630123204000",
            "children": [{
                "label": "卡路村",
                "value": "630123204200",
                "children": null
            }, {
                "label": "申中村",
                "value": "630123204201",
                "children": null
            }, {
                "label": "前沟村",
                "value": "630123204202",
                "children": null
            }, {
                "label": "莫布拉村",
                "value": "630123204203",
                "children": null
            }, {
                "label": "庙沟脑村",
                "value": "630123204204",
                "children": null
            }, {
                "label": "立达村",
                "value": "630123204205",
                "children": null
            }, {
                "label": "口子村",
                "value": "630123204206",
                "children": null
            }, {
                "label": "星泉村",
                "value": "630123204207",
                "children": null
            }, {
                "label": "俊家庄村",
                "value": "630123204208",
                "children": null
            }, {
                "label": "韭菜沟村",
                "value": "630123204209",
                "children": null
            }, {
                "label": "窑庄村",
                "value": "630123204210",
                "children": null
            }, {
                "label": "后沟村",
                "value": "630123204211",
                "children": null
            }, {
                "label": "河拉村",
                "value": "630123204212",
                "children": null
            }, {
                "label": "庙沟村",
                "value": "630123204213",
                "children": null
            }, {
                "label": "大山根村",
                "value": "630123204214",
                "children": null
            }, {
                "label": "大路村",
                "value": "630123204215",
                "children": null
            }]
        }, {
            "label": "巴燕乡",
            "value": "630123205000",
            "children": [{
                "label": "巴燕村",
                "value": "630123205200",
                "children": null
            }, {
                "label": "元山尔村",
                "value": "630123205201",
                "children": null
            }, {
                "label": "新寺村",
                "value": "630123205202",
                "children": null
            }, {
                "label": "下寺村",
                "value": "630123205203",
                "children": null
            }, {
                "label": "下浪湾村",
                "value": "630123205204",
                "children": null
            }, {
                "label": "下胡丹村",
                "value": "630123205205",
                "children": null
            }, {
                "label": "西岭台村",
                "value": "630123205206",
                "children": null
            }, {
                "label": "石门尔村",
                "value": "630123205207",
                "children": null
            }, {
                "label": "上浪湾村",
                "value": "630123205208",
                "children": null
            }, {
                "label": "上胡丹村",
                "value": "630123205209",
                "children": null
            }, {
                "label": "莫合尔村",
                "value": "630123205210",
                "children": null
            }, {
                "label": "居士浪村",
                "value": "630123205211",
                "children": null
            }, {
                "label": "福海村",
                "value": "630123205212",
                "children": null
            }, {
                "label": "巴燕峡村",
                "value": "630123205213",
                "children": null
            }, {
                "label": "扎汉村",
                "value": "630123205214",
                "children": null
            }]
        }, {
            "label": "寺寨乡",
            "value": "630123206000",
            "children": [{
                "label": "小寺尔村",
                "value": "630123206200",
                "children": null
            }, {
                "label": "华尖村",
                "value": "630123206201",
                "children": null
            }, {
                "label": "下寨村",
                "value": "630123206202",
                "children": null
            }, {
                "label": "西扎湾村",
                "value": "630123206203",
                "children": null
            }, {
                "label": "草原村",
                "value": "630123206204",
                "children": null
            }, {
                "label": "长岭村",
                "value": "630123206205",
                "children": null
            }, {
                "label": "上寨村",
                "value": "630123206206",
                "children": null
            }, {
                "label": "簸箕湾村",
                "value": "630123206207",
                "children": null
            }, {
                "label": "乌图村",
                "value": "630123206208",
                "children": null
            }, {
                "label": "烽火村",
                "value": "630123206209",
                "children": null
            }, {
                "label": "马吉岭村",
                "value": "630123206210",
                "children": null
            }, {
                "label": "阳坡湾村",
                "value": "630123206211",
                "children": null
            }, {
                "label": "五岭村",
                "value": "630123206212",
                "children": null
            }]
        }]
    }]
}]
export default {
    region
}